import React from 'react'
import AboutHeading from '../components/AboutHeading'
import AboutContent from '../components/AboutContent'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Background from "../components/images/banner.jpg"
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import { useSeo } from '../hooks/useSeo'

function SustainabilityPage ({ data }) {
    const aboutContent = data && data.allWpAbout.edges.length >= 1
    ? data.allWpAbout.edges[0].node.acf : null
    
    const banner = aboutContent.component.filter(ab => ab.fieldGroupName === "about_Acf_Component_MainBanner")
    const textWithMedia = aboutContent.component.filter(ab => ab.fieldGroupName === "about_Acf_Component_TextWithMedia")
    const textWithHeading = aboutContent.component.filter(ab => ab.fieldGroupName === "about_Acf_Component_TextWithHeading")
    const {metaDescription,title} = useSeo("cG9zdDoxMjY3Mw==")
    return (
        <Layout>
            <Seo title={title} description={metaDescription}/>
            <Banner 
                banner={banner}
                btnNext={true}
            />
            <AboutHeading 
                textWithMedia = {textWithMedia}  
                className="type-first"
            />
            <AboutContent textWithHeading={textWithHeading}/>
        </Layout>
    )
}

export default SustainabilityPage

export const GET_ABOUT_US = graphql`
    query getSustainability {
        allWpAbout(filter: {
            slug: {
                eq: "sustainability"
            }
        }) {
            edges {
                node {
                    title
                    slug
                    acf {
                        component {
                            ... on WpAbout_Acf_Component_MainBanner {
                                fieldGroupName
                                heading
                                text
                                image {
                                    sourceUrl
                                }
                            }
                            ... on WpAbout_Acf_Component_TextWithMedia {
                                fieldGroupName
                                subHeading
                                heading
                                text
                                media {
                                    mediaItemUrl
                                    mimeType
                                }
                            }
                            ... on WpAbout_Acf_Component_TextWithHeading {
                                fieldGroupName
                                subHeading
                                heading
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`